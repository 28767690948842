import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Grid,
  GridItem,
  Button,
  Link,
  IconButton,
  Flex,
  Heading,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Highlight,
  useDisclosure,
  Divider,
  useToast,
} from '@chakra-ui/react';

import { HamburgerIcon } from '@chakra-ui/icons';
import { selectAuthState } from '../../redux/authSlice'; // Adjust path as per your project

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const isAuthenticated = useSelector(selectAuthState);
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50); // Adjust as needed
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavigation = path => {
    if (isAuthenticated) {
      navigate(path);
    } else {
      toast({
        variant: 'subtle',
        title: 'Authentication Required',
        description: 'You need to be authenticated to access this page.',
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: 'top',
      });
      setTimeout(() => {
        navigate('/connectwallet');
      }, 0); // Wait for toast to finish before navigating
    }
  };

  const headerBgColor = [
    '/launchlanding',
    '/structure',
    '/development',
    '/presence',
    '/launch',
  ].includes(location.pathname)
    ? 'rgba(69, 69, 69, 0.75)'
    : 'rgba(255, 255, 255, 0.75)';
    

  const menuIconColor = [
    '/launchlanding',
    '/structure',
    '/development',
    '/presence',
    '/launch',
  ].includes(location.pathname)
    ? '#fff'
    : '#454545';

  const hiddenheader = ['/connectwallet'].includes(location.pathname)
    ? 'none'
    : 'block';

  return (
    <Box
      as="header"
      display={hiddenheader}
      bg={headerBgColor}
      backdropFilter="blur(8px)"
      color="white"
      w="100%"
      maxW="1440px"
      h={isScrolled ? '5rem' : '6rem'}
      position="fixed"
      p={4}
      boxShadow="md"
      transition="all 0.3s ease-in-out !important"
      zIndex={10}
      borderBottom="1px solid rgba(200, 200, 200, .1)"
    >
      <Grid
        templateColumns="repeat(4, 1fr)"
        py={isScrolled ? 3 : 5}
        transition="all 0.3s ease-in-out !important"
      >
        <GridItem
          colSpan={{ base: 3, md: 1 }}
          pl={{ base: 32, lg: 12 }}
          textAlign="center"
        >
          <Heading
            size="md"
            fontWeight="bolder"
            color="#000"
            transition="all 0.3s ease-in-out !important"
            textDecoration="none"
            as={NavLink}
            to="/"
          >
            <Highlight
              query="b0xx"
              styles={{
                px: '0',
                py: '4',
                rounded: '3px',
                bg: '#21c5b6',
                color: '#fff',
              }}
            >
              Launchb0xx
            </Highlight>
          </Heading>
        </GridItem>
        <GridItem
          colSpan={3}
          display={{ base: 'none', md: 'inline' }}
          color="#000"
          textAlign="right"
          pr={{ base: 4, lg: 12 }}
        >
          <Link
            as={NavLink}
            to="/structure"
            color="#000"
            textDecoration="none"
            _hover={{
              color: '#fff',
              textDecoration: 'underlined',
              textDecorationColor: '#fff',
            }}
            transition="all 0.3s ease-in-out !important"
            px={2}
            onClick={() => handleNavigation('/structure')}
          >
            Structure
          </Link>
          <Link
            as={NavLink}
            to="/development"
            color="#000"
            textDecoration="none"
            _hover={{
              color: '#fff',
              textDecoration: 'underlined',
              textDecorationColor: '#fff',
            }}
            transition="all 0.3s ease-in-out !important"
            px={2}
            onClick={() => handleNavigation('/development')}
          >
            Development
          </Link>
          <Link
            as={NavLink}
            to="/presence"
            color="#000"
            textDecoration="none"
            _hover={{
              color: '#fff',
              textDecoration: 'underlined',
              textDecorationColor: '#fff',
            }}
            transition="all 0.3s ease-in-out !important"
            px={2}
            onClick={() => handleNavigation('/presence')}
          >
            Presence
          </Link>
          <Link
            as={NavLink}
            to="/launch"
            color="#000"
            textDecoration="none"
            _hover={{
              color: '#fff',
              textDecoration: 'underlined',
              textDecorationColor: '#fff',
            }}
            transition="all 0.3s ease-in-out !important"
            px={2}
            onClick={() => handleNavigation('/launch')}
          >
            Launch
          </Link>
          <Button
            color="#fff"
            bg="#000"
            onClick={() => handleNavigation('/b0xx')}
            px={2}
          >
            B0xx
          </Button>
        </GridItem>
        <GridItem
          colSpan={1}
          display={{ base: 'flex', md: 'none' }}
          justifyContent="flex-end"
        >
          <IconButton
            icon={<HamburgerIcon />}
            aria-label="Open Menu"
            onClick={onOpen}
            bg="transparent"
            color={menuIconColor}
          />
        </GridItem>
      </Grid>
      {/* Responsive Drawer */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent
            bg={headerBgColor}
            transition="all 0.2s ease-out !important"
            p={12}
          >
            <DrawerCloseButton />
            <DrawerHeader textAlign="center" my={12}>
              <Heading
                size="md"
                fontWeight="bolder"
                color="#000"
                textDecoration="none"
                as={NavLink}
                to="/"
              >
                <Highlight
                  query="b0xx"
                  styles={{
                    px: '0',
                    py: '4',
                    rounded: '3px',
                    bg: '#21c5b6',
                    color: '#fff',
                  }}
                >
                  Launchb0xx
                </Highlight>
              </Heading>
            </DrawerHeader>
            <Divider />
            <DrawerBody mt={12}>
              <Flex direction="column" align="center" gap="4">
                <Link
                  as={NavLink}
                  to="/structure"
                  onClick={() => handleNavigation('/structure')}
                >
                  Structure
                </Link>
                <Link
                  as={NavLink}
                  to="/development"
                  onClick={() => handleNavigation('/development')}
                >
                  Development
                </Link>
                <Link
                  as={NavLink}
                  to="/presence"
                  onClick={() => handleNavigation('/presence')}
                >
                  Presence
                </Link>
                <Link
                  as={NavLink}
                  to="/launch"
                  onClick={() => handleNavigation('/launch')}
                >
                  Launch
                </Link>
                <Link
                  as={NavLink}
                  to="/#"
                  onClick={() => handleNavigation('/b0xx')}
                >
                  B0xx
                </Link>
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Box>
  );
};

export default Header;
