import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authenticate } from '../../redux/authSlice';
import {
  Box,
  Grid,
  Button,
  Heading,
  Highlight,
  Text,
  Input,
  HStack,
  Divider,
  useToast,
} from '@chakra-ui/react';
import './animation.css'; // Import CSS for custom animations

const ConnectWallet = () => {
  const [username, setUsername] = useState('');
  const [error, setError] = useState(false);
  const [showError, setShowError] = useState(false); // State to control error message display
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const inputRef = useRef(null); // Ref for the input element

  useEffect(() => {
    // Focus on the input field when the component mounts
    inputRef.current.focus();
  }, []); // Empty dependency array ensures this effect runs only once

  const handleConnectWallet = () => {
    if (username === 'launchboxx') {
      dispatch(authenticate());
      toast({
        variant: 'subtle',
        title: 'Success!',
        description: 'Username accepted. Redirecting to B0xx...',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top',
      });
      setTimeout(() => {
        navigate('/');
      }, 1000); // Wait for toast to finish before navigating
    } else {
      setError(true);
      setShowError(true); // Show the error message
      // Reset error state and hide error message after 0.3 seconds
      setTimeout(() => {
        setError(false);
      }, 500);
    }
  };

  const handleKeyPress = e => {
    // Trigger handleConnectWallet on Enter key press
    if (e.key === 'Enter') {
      handleConnectWallet();
    }
  };

  return (
    <Box
      w="100%"
      px={2}
      py={24}
      maxHeight="199vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      <Heading
        lineHeight="tall"
        color="#000"
        fontSize="16px"
        as={NavLink}
        to="/"
      >
        <Highlight
          query="b0xx"
          styles={{
            px: '0',
            py: '4',
            rounded: '3px',
            bg: '#21c5b6',
            color: '#fff',
          }}
        >
          Launchb0xx
        </Highlight>
      </Heading>
      <Box maxW={500}>
        <Grid alignItems="center" p={4}>
          <Text fontSize="24px" my={6} textAlign="center" fontWeight="bolder">
            Create an account:
          </Text>
          <HStack spacing={4} align="center">
            <Divider borderColor="#888" />
            <Text color="#888"> Username: </Text>
            <Divider borderColor="#888" />
          </HStack>

          <Input
            ref={inputRef}
            className={error ? 'input-error' : ''}
            my={6}
            variant="filled"
            textAlign="center"
            placeholder="Somebody.Launch"
            value={username}
            onChange={e => setUsername(e.target.value)}
            onKeyPress={handleKeyPress} // Handle Enter key press
            borderColor={error ? 'red.500' : 'inherit'}
            color={error ? 'red.500' : 'inherit'}
          />

          {showError && (
            <Text color="red.500" mt={2} className="error-message">
              Invalid username, Try again!
            </Text>
          )}

          <Text textAlign="center">Connect your seed wallet to join.</Text>
          <Button
            bg="#000"
            color="#fff"
            _hover={{ bg: '#828282' }}
            _active={{ bg: '#aaa', color: '#000' }}
            my={6}
            onClick={handleConnectWallet}
          >
            Connect Wallet
          </Button>
          <Text color="#828282">
            By connecting wallet, you agree to our
            <br />
            <Text as="span" color="#000">
              Terms of Service
            </Text>{' '}
            and{' '}
            <Text as="span" color="#000">
              Privacy Policy
            </Text>
          </Text>
        </Grid>
      </Box>
    </Box>
  );
};

export default ConnectWallet;
